<template>
  <transition name="fade">
    <div v-if="!state.loading">  
    <div class="p-0 m-0 card mb-5 mb-xxl-8" >
        <div class="card-header pb-0">
            <div class="card-title">
              <div class="card-title">
                <i class="bi bi-arrow-left-short fs-2tx text-primary text-hover-gray-600 cursor-pointer me-4" @click="close()"></i>
                <div class="fs-4 text-uppercase text-gray-800" >{{ state.title }}</div>
              </div>
            </div>
            <div class="d-flex align-items-center m-0" v-if="!modeDisabled()">
              <el-popconfirm title="Supprimer cette note de frais ?" @confirm="removeN2F()"  :confirm-button-text="$t('Oui')" :cancel-button-text="$t('Non')"  icon-color="danger">
                <template #reference>
                  <i class="bi bi-trash3 fs-1 text-danger text-hover-gray-600 cursor-pointer me-8" ></i>
                </template>
              </el-popconfirm>
            </div>
        </div>
      </div>

    <div class="card mb-5 mb-xxl-8 mx-4 mx-md-8" >
        <div class="card-body pt-2 pt-md-5 pb-0">
            <div class="row mb-5">
                <label class="col-lg-4 col-form-label fw-semobold fs-6 required">Libellé de la note de frais</label>
                <div class="col-lg-8 col-12 fv-row">
                    <input :disabled="modeDisabled()" type="text" class="form-control ps-4 text-center fs-4" @change="modLabel()" v-model="state.rN2f.not_libelle"/>
                </div>
            </div>
        </div>
    </div>


    <div class="card mb-5 mb-xxl-8 mx-4 mx-md-8" >
        <div class="card-header pb-0">
            <div class="card-title">
                <div class=" my-2">
                    <div class="fw-bold fs-2">Total de {{ state.rN2f.montant_euro }} €</div>
                    <div class="text-muted fw-bold d-block fs-6">{{ state.rN2f.tot_depense > 0 ? state.rN2f.tot_depense : 'Aucune' }} dépense{{ state.rN2f.tot_depense > 1 ? 's' : '' }}</div>
                </div>
            </div>
            <div v-if="state.rN2f.not_status=='Brouillon' || state.rN2f.not_status=='Refusé'" class="m-0 m-md-6 mb-6 text-center w-100 w-md-auto mw-md-450px">
 
              <template v-if="state.rN2f && state.rN2f.lines && state.rN2f.lines.length > 0">
                <el-popconfirm title="Transmettre cette note de frais ? Elle deviendra non modifiable" @confirm="sendN2F('En cours de traitement')"  :confirm-button-text="$t('Oui')" :cancel-button-text="$t('Non')"  icon-color="danger">
                  <template #reference>
                    <button class="btn btn-success me-2 w-100">
                      <i class="bi bi-send fs-2 text-white text-hover-gray-600 cursor-pointer me-4" ></i>
                      Transmettre la note de frais
                    </button>
                  </template>
                </el-popconfirm>
              </template>

              <button v-if="state.rN2f && state.rN2f.lines && state.rN2f.lines.length > 0" class="btn btn-primary me-2 w-100 mt-3" @click="addEditDepense()">
                <i class="bi bi-bag-plus fs-2 text-white text-hover-gray-600 cursor-pointer me-4" ></i>
                Nouvelle dépense
              </button>
            </div>

            <div v-if="state.rN2f.not_status=='En cours de traitement'" class="m-0 m-md-6 mb-6 text-center w-100 w-md-auto mw-md-450px">
 
              <template v-if="state.rN2f && state.rN2f.lines && state.rN2f.lines.length > 0 && state.allRest && state.allRest.length == 0">
                <el-popconfirm title="Valider cette note de frais ?" @confirm="sendN2F('En attente de paiement')"  :confirm-button-text="$t('Oui')" :cancel-button-text="$t('Non')"  icon-color="danger">
                  <template #reference>
                    <button class="btn btn-success me-2 w-100">
                      <i class="bi bi-check2 fs-1 text-white text-hover-gray-600 cursor-pointer me-4" ></i>
                      Valider cette note de frais
                    </button>
                  </template>
                </el-popconfirm>
              </template>

              <template v-if="state.rN2f && state.rN2f.lines && state.rN2f.lines.length > 0 && state.allRest && state.allRest.length == 0">
                <el-popconfirm title="Refuser cette note de frais ?" @confirm="sendN2F('Refusé')"  :confirm-button-text="$t('Oui')" :cancel-button-text="$t('Non')"  icon-color="danger">
                  <template #reference>
                    <button class="btn btn-danger me-2 w-100 mt-3">
                      <i class="bi bi-file-earmark-x fs-2 text-white text-hover-gray-600 cursor-pointer me-4" ></i>
                      Refuser cette note de frais
                    </button>
                  </template>
                </el-popconfirm>
              </template>

            </div>
            <div v-if="state.rN2f.not_status=='En attente de paiement'" class="m-0 m-md-6 mb-6 text-center w-100 w-md-auto mw-md-450px">
              <template v-if="state.rN2f && state.rN2f.lines && state.rN2f.lines.length > 0 && state.allRest && state.allRest.length == 0">
                <el-popconfirm title="Passer cette note de frais comme payée ?" @confirm="sendN2F('Payé')"  :confirm-button-text="$t('Oui')" :cancel-button-text="$t('Non')"  icon-color="danger">
                  <template #reference>
                    <button class="btn btn-success me-2 w-100">
                      <i class="bi bi-cash fs-1 text-white text-hover-gray-600 cursor-pointer me-4" ></i>
                      Passer cette note de frais comme payée
                    </button>
                  </template>
                </el-popconfirm>
              </template>
            </div>

        </div>

        <div class="card-header pb-0" v-if="state.rN2f.not_json_historique && state.rN2f.not_json_historique.length > 0 || (state.allRest.length == 0 && state.rN2f.not_status=='En cours de traitement')">
          <div class="card-title d-flex justify-content-between w-100">
            <div class="fw-bolder fs-4 my-2">Commentaires de retour</div>
            <button class="btn text-primary d-flex align-items-center" v-if="state.rN2f.not_json_historique && state.rN2f.not_json_historique.length > 1 || (state.allRest.length == 0 && state.rN2f.not_status=='En cours de traitement')" @click="state.comsHisto = !state.comsHisto"><i class="bi bi-chevron-down text-primary p-0 px-2" :style="!state.comsHisto ? 'rotate: -90deg' : ''" style="transition: rotate .1s;"></i>Historique</button>
          </div>
          <div class="card-body p-0 pb-5">
            <div class="col-lg-12 col-12 fv-row" v-if="state.rN2f.not_status=='En cours de traitement' && state.allRest.length == 0">
              <p class="fw-bolder fs-8 ms-3 m-1 text-primary">{{ formatDate(new Date()) }}</p>
              <textarea class="form-control form-control-solid rounded-3" rows="2" v-model="state.newComment" placeholder="Saisissez le commentaire..."/>
            </div>
            <div v-for="(item, index) in state.rN2f.not_json_historique" :key="index">
              <div class="col-lg-12 col-12 fv-row mt-2" v-if="state.comsHisto || (index == 0 && (state.allRest.length > 0 || state.rN2f.not_status != 'En cours de traitement'))">
                <p class="fw-bolder fs-8 ms-3 m-1 text-primary">{{ formatDate(item.date) }}</p>
                <textarea disabled class="form-control form-control-solid rounded-3" rows="2" v-model="item.comment"/>
              </div>
            </div>
          </div>
        </div>
    </div>

    <el-empty v-if="state.rN2f && state.rN2f.lines && state.rN2f.lines.length == 0"  description="Aucune dépense pour le moment...">
        <br><el-button v-if="state.rN2f.not_status=='Brouillon' || state.rN2f.not_status=='Refusé'" type="primary" @click="addEditDepense()" >Ajouter une dépense sur cette note de frais</el-button>
    </el-empty>

    <div :key="state.refresh">
      <lstItems v-for="categ in state.rN2f.categ" 
          @clickDepense="addEditDepense($event)"
          :key="categ.lno_categorie" :categ="categ"
          :lines="returnLineCateg(categ.lno_categorie)"
          widget-classes="card-xl-stretch mb-4 mb-lg-12 mx-4 mx-md-8">
      </lstItems>
    </div>  
      
    <el-drawer v-model="state.drawerAddItem" :with-header="false" size="900px" modal-class="modal-transp" :lock-scroll="false"  :show-close="false" :before-close="confirmDrawer"> 
      <DepenseAddEdit :perm="(state.allRest && state.allRest.length == 0)" ref="mDepenseAddEdit" :idN2F="state.rN2f.not_seq" :idFolder="state.idFolder" :idDepense="state.idDepense"  :isOpen="state.drawerAddItem" :key="state.drawerAddItem" @refresh="refresh" @closeDepense="state.drawerAddItem = 0"/>
    </el-drawer>
  
    </div>
    <div v-else v-loading="true" style="position: absolute; top: 50%; left: 50%"></div>
  </transition>

</template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted, reactive } from "vue";
  import mAxiosApi from "@/api";
  import lstItems from "./lstItems.vue";
  import DepenseAddEdit from "./DepenseAddEdit.vue";
  import { FileService } from "@/core/services/FileService";
  import * as moment from "moment"
   
  export default defineComponent({
    name: "General",
    components : {
        lstItems, DepenseAddEdit
    },
    props : {
      idN2F : Number,
      isOpen : Number,
      apiName : String,
    },
    setup(props, { emit }) {

    const dialogImageUrl = ref('')
    const dialogVisible = ref(false)
    const disabled = ref(false)

    const state = reactive({
        drawerAddItem : 0,
        masqueSubStitle : 0,
        rN2f : {} as any,
        currency : {} as any,
        title : 'Nouvelle note de frais',
        idDepense : 0,
        idFolder: 0,
        refresh : 0,
        idN2F : 0,
        allRest: {} as any,
        loading: true,
        comsHisto: false,
        newComment: '',
    });
    const N2FfileService = new FileService("n2f")
    
    onMounted(async() => {
      
      const allRest = await mAxiosApi.prototype.getAxios("/getPermissions");
      state.allRest =  allRest.results.filter(
          (item: any) =>item.per_code_permission == 'ERP_TRAITE_N2F'
      );

      if (!props.isOpen) return false;
      if (props.idN2F as any > 0) {
          const getN2F = await mAxiosApi.prototype.getAxios("/"+ props.apiName +"/" + props.idN2F);
          const getFolder = await N2FfileService.createFolder(("Note de frais " + getN2F.results[0].not_seq));
          state.idFolder = getFolder
          state.rN2f = getN2F.results[0];
          if (((state.rN2f.not_status == 'Brouillon' || state.rN2f.not_status == 'Refusé') && state.allRest && state.allRest.length != 0) ||
          ((state.rN2f.not_status != 'Payé') && state.allRest && state.allRest.length == 0)) {
            state.title = "Modifier une note de frais";
          }else{
            state.title = "Afficher une note de frais";
          }
      } else{
        const newN2F = await mAxiosApi.prototype.getAxios("/NewN2F");
        const newFolder = await N2FfileService.createFolder(("Note de frais " + newN2F.results.not_seq));
        state.idFolder = newFolder;
        const getN2F = await mAxiosApi.prototype.getAxios("/"+ props.apiName +"/" + newN2F.results.not_seq);
        state.rN2f = getN2F.results[0];
        refreshParent();
      }

      state.rN2f.not_json_historique = JSON.parse(state.rN2f.not_json_historique)
      if(!state.rN2f.not_json_historique) state.rN2f.not_json_historique = [];
      state.rN2f.not_json_historique = state.rN2f.not_json_historique.reverse();
      
      state.idN2F = state.rN2f.not_seq;

      const response = await mAxiosApi.prototype.getAxios("/getCurrency");
      state.currency = response.results[0];

      state.loading = false;

    });

    const addEditDepense = (id = 0) => {
      state.idDepense = id;
      state.drawerAddItem = 1;
    };

    const close = () => {
      emit("closeN2FAddEdit", 0);
    };

    const returnLineCateg = (categ) => {
      const arrayFilter = state.rN2f.lines.filter(
          (item: any) =>item.lno_categorie == categ
        );
        return arrayFilter;

    };

    

    const removeN2F = () => {
      const rN2F = {
        not_seq: state.rN2f.not_seq,
      };

      mAxiosApi.post("/deleteN2F", rN2F)
      .then(() => {
        refreshParent();
        close();
      })

    };

    const sendN2F = (status) => {
      const rN2F = {
        not_seq: state.rN2f.not_seq,
        not_status: status,
        newComment: state.newComment 
      };

      mAxiosApi.post("/sendN2F", rN2F)
      .then(() => {
        refreshParent();
        close();
      })

    };

    

    const modLabel = () => {

      const rLabel = {
        not_seq: state.rN2f.not_seq,
        not_libelle: state.rN2f.not_libelle,
      };

      mAxiosApi.post("/editLabelN2F", rLabel)
      .then(() => {
         refreshParent();
      })

    };

    const refreshParent = () => {
      emit("refresh", 1);
    };

    const mDepenseAddEdit = ref(null);
    const confirmDrawer = () => {
      (mDepenseAddEdit.value as any).handleCloseL2f((mDepenseAddEdit.value as any).ValidConfirm);
    };

    const refresh = async () => {
      const getN2F = await mAxiosApi.prototype.getAxios("/"+ props.apiName +"/" + state.idN2F);
      state.rN2f = getN2F.results[0];
      state.refresh = state.refresh +1;
      refreshParent();
    };

    const modeDisabled = () => {
      if (state.rN2f.not_status=='Brouillon' || state.rN2f.not_status=='Refusé') return false;
      return true;
    };

    const formatDate = (date) => {
      return moment.default(date).format("DD/MM/YYYY");
    }

      return {
          disabled,
          dialogImageUrl,
          dialogVisible,
          state,
          addEditDepense,
          close,
          returnLineCateg,
          refreshParent,
          refresh,
          modLabel,
          removeN2F,
          mDepenseAddEdit,
          confirmDrawer,
          sendN2F,
          modeDisabled,
          formatDate,
      };
    },
  });
  </script>
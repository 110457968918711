
import { defineComponent, onMounted, reactive } from "vue";
import * as moment from "moment";

export default defineComponent({
  name: "kt-widget-3",
  components: {
  },
  props: {
    widgetClasses: String,
    categ: Array,
    lines : Array,
  },
  setup(props, { emit }) {

    const state = reactive({
      categ: {}   as any,
      lines : {} as any,
    });

    const toDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    
    const clickDepense = (id) => {
      emit("clickDepense", id);
    };


    onMounted(() => {
      state.categ = props.categ
      state.lines = props.lines;
    });


    return {
      state,
      toDate,
      clickDepense
    };
  },
});

<template>
  <!--begin::List Widget 3-->
  <div class="card" :class="widgetClasses">
    <!--begin::Header-->
    <div class="card-header border-0">
      <h3 class="card-title fw-bolder text-dark">{{  state.categ.c2f_libelle  }}</h3>

      <div class="card-toolbar pe-4 fs-6">
        <span class="text-primary me-4">Sous-total : </span><b>{{ state.categ.total_euro }} €</b>
      </div>
      
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body pt-2">
      <template v-for="(item, index) in state.lines" :key="index">
        <!--begin::Item-->
        <div
          :class="{ 'mb-0': state.lines.length - 1 !== index }"
          class="d-flex align-items-center bg-hover-light-primary p-2"
          @click="clickDepense(item.lno_seq)"
        >
          <!--begin::Bullet-->
          <span
            class="bullet bullet-vertical h-40px me-4"
            :class="`bg-${item.color}`"
          ></span>
          <!--end::Bullet-->



          <!--begin::Description-->
          <div class="flex-grow-1">
            <i v-if="item.color=='warning'" class="bi bi-exclamation-triangle-fill me-2 text-warning"></i>
            <span
              href="#"
              class="text-gray-800 fw-bolder fs-6"
              >{{ item.lno_description }}
            </span>

            <span class="text-muted fw-bold d-block" v-if="item.lno_date">Le {{ toDate(item.lno_date) }}</span>
            <span class="text-muted fw-bold d-block" v-else>Date inconnue...</span>
          </div>
          <!--end::Description-->

          <span
            class="badge fs-7 fw-bolder"
            :class="`badge-secondary`"
            >{{ item.lno_montant }} €</span
          >
        </div>
        <!--end:Item-->
      </template>
    </div>
    <!--end::Body-->
  </div>
  <!--end:List Widget 3-->
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import * as moment from "moment";

export default defineComponent({
  name: "kt-widget-3",
  components: {
  },
  props: {
    widgetClasses: String,
    categ: Array,
    lines : Array,
  },
  setup(props, { emit }) {

    const state = reactive({
      categ: {}   as any,
      lines : {} as any,
    });

    const toDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    
    const clickDepense = (id) => {
      emit("clickDepense", id);
    };


    onMounted(() => {
      state.categ = props.categ
      state.lines = props.lines;
    });


    return {
      state,
      toDate,
      clickDepense
    };
  },
});
</script>

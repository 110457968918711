<template>
  <transition name="fade">
    <div v-if="!state.loading">  
   
    <div class="p-0 m-0 card mb-2 mb-xxl-4" >
      <div class="card-header pb-0">
          <div class="card-title">
            <div class="card-title">
              <i class="bi bi-arrow-left-short fs-2tx text-primary text-hover-gray-600 cursor-pointer me-4" @click="handleCloseL2f(ValidConfirm)"></i>
              <div class="fs-4 text-uppercase text-gray-800" >{{ state.title }}</div>
            </div>
          </div>
          <div class="my-4 mt-7" v-if="!modeDisabled() || (state.rDepense.not_status == 'En cours de traitement' && perm)">
            <el-popconfirm title="Supprimer cette dépense ?" @confirm="removeL2F()"  :confirm-button-text="$t('Oui')" :cancel-button-text="$t('Non')"  icon-color="danger">
                <template #reference>
                  <i class="bi bi-trash3 fs-1 text-danger text-hover-gray-600 cursor-pointer me-4" ></i>
                </template>
            </el-popconfirm>
            <i  @click="addN2F()" v-if="state.modeAJt == 1 && !modeDisabled()" v-loading="state.loadingUpload" class="px-5 bg-success py-1 bi bi-check-circle-fill fs-1 text-white text-hover-gray-600 cursor-pointer me-4" :class="{'pe-none': state.loadingUpload}" style="border-radius: 5px"></i>
            
          </div>
      </div>
    </div>

    <div :class="`${state.modeAJt == 0 ? 'text-center' : ''}`" style="`height:state.modeAJt == 0 ? 'calc(100vh - 100px)' : '100px'`  display: flex; justify-content: center; align-items: center; flex-direction: column;">
      
      <el-upload  v-show="state.rDepense.lno_categorie != 'trajet'"
        v-model:file-list="state.uploadedFiles" drag action="#" list-type="picture-card" :auto-upload="false" 
          :on-change="handleChange" style="display: flex; justify-content: center;"  class="bg-primary" >
              <i class="bi bi-camera fs-1 text-dark" style="font-size: 2.475em !important; "></i>
              <div class="text-dark fs-7 text-uppercase">
                <b>Pré-saisie<br>via IA</b>
              </div>
              <template #file="{ file }">
                  <div>
                      <img v-if="file.name.indexOf('.pdf') > -1 " src="https://cdn-icons-png.flaticon.com/128/4208/4208479.png"  class="el-upload-list__item-thumbnail" alt="" />
                      <img v-else class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                      <span class="el-upload-list__item-actions">
                      <span class="el-upload-list__item-preview fs-2" @click="handlePictureCardPreview(file)" >
                          <el-icon><zoom-in /></el-icon>
                      </span>
                      </span>
                  </div>
              </template>
      </el-upload>

      <div v-if="state.modeAJt == 0" class="mt-7">- ou -</div>
      <button v-if="state.modeAJt == 0" class="btn btn-sm btn-secondary mt-5" @click="state.modeAJt=1">Saisie manuelle</button>

      <div v-if="state.modeAJt == 1" class="mt-2">
        <div class="card mb-5 mb-xxl-8 mx-4 mx-md-8" >
          <div class="card-body pt-2 pt-md-5 pb-0">
            <div class="row mb-5">
                  <label class="col-lg-4 col-form-label fw-semobold fs-6 required">Catégorie</label>
                  <div class="col-lg-8 col-12 fv-row d-flex">
                    <select :disabled="modeDisabled()" v-model="state.rDepense.lno_categorie" name="status" data-control="select2"  @input="changeCateg" data-hide-search="true" class="form-select form-select fw-bolder " >
                      <option value="material" selected>Achat petit materiel</option>
                      <option value="gasoline" >Carburant</option>
                      <option value="accommodation" >Hébérgement</option>
                      <option value="parking" >Parking</option>
                      <option value="toll" >Péage</option>
                      <option value="telecom" >Télécom</option>
                      <option value="transport" >Transport</option>
                      <option value="trajet" >Trajet (Indemnités kilométriques)</option>
                      <option value="food" >Restauration</option>
                      <option value="miscellaneous" >Divers</option>
                    </select>
                  </div>
              </div>    

              <div class="row mb-5">
                  <label class="col-lg-4 col-form-label fw-semobold fs-6 required">Date</label>
                  <div class="col-lg-4 col-12 fv-row w-175px">
                      <input :disabled="modeDisabled()" v-model="state.rDepense.lno_date" @change="changeBareme" type="date" class="form-control ps-4" />
                  </div>
              </div>
              <div class="row mb-5">
                  <label class="col-lg-4 col-form-label fw-semobold fs-6 required">Description / Vendeur</label>
                  <div class="col-lg-8 col-12 fv-row">
                      <input :disabled="modeDisabled()" v-model="state.rDepense.lno_description" type="text" class="form-control ps-4" />
                  </div>
              </div>
              <div v-if="state.rDepense.lno_categorie != 'trajet'" class="row mb-5">
                  <label class="col-lg-4 col-form-label fw-semobold fs-6 required">Montant Total TTC</label>
                  <div class="col-lg-5 col-7 fv-row text-right w-175px">
                      <input :disabled="modeDisabled()" v-model="state.rDepense.lno_montant" type="number" class="form-control ps-4" />
                  </div>
                  <div class="col-lg-3 col-5 fv-row">
                    <select :disabled="modeDisabled()" name="status" v-model="state.rDepense.lno_currency" data-control="select2" data-hide-search="true" class="form-select form-select fw-bolder  w-125px" >
                      <option value="EUR" selected>€</option>
                      <option value="USD" selected>$</option>
                      <option value="CHF" selected>CHF</option>
                      <option value="CAD" selected>CAD</option>
                    </select>
                  </div>
              </div>
              <div v-if="state.rDepense.lno_categorie != 'trajet' && state.rDepense.lno_currency != 'EUR'" class="row mb-5">
                  <label class="col-lg-4 col-form-label fw-semobold fs-6">Soit Total TTC</label>
                  <div class="col-lg-5 col-7 fv-row text-right w-175px">
                    <div class="input-group">
                      <input type="text" disabled :value="totalEuro(state.rDepense.lno_montant)"  class="form-control ps-4" />
                      <span class="input-group-text">€</span>
                    </div>
                  </div>

              </div>
              <div v-if="state.rDepense.lno_categorie != 'trajet' && state.rDepense.lno_categorie != 'accommodation'" class="row mb-5">
                  <label class="col-lg-4 col-form-label fw-semobold fs-6 required">Total TVA (1) <i @click="calcTVA()" v-if="!modeDisabled() && !parseInt(state.rDepense.lno_tx_tva2)>0" alt="Calculer la TVA" class="fs-1 text-primary bi bi-calculator bg-hover-primary text-hover-white br-5" />
                  </label>
                  <div class="col-lg-5 col-7 fv-row text-right mw-175px">
                    <div class="input-group">
                      <input :disabled="modeDisabled()" type="number" v-model="state.rDepense.lno_mnt_tva1" class="form-control ps-4" />
                      <span class="input-group-text">{{ currencSymbol(state.rDepense.lno_currency) }}</span>
                    </div>
                      
                  </div>
                  <div class="col-lg-3 col-3 fv-row d-flex w-100px">
                    <select :disabled="modeDisabled()" name="tva1" v-model="state.rDepense.lno_tx_tva1" data-control="select2" data-hide-search="true" class="form-select form-select fw-bolder  w-125px" >
                      <option value="5.50" >à 5.5 %</option>
                      <option value="10.00" >à 10 %</option>
                      <option value="20.00">à 20 %</option>
                    </select>
                    <button v-if="!modeDisabled() && !parseInt(state.rDepense.lno_tx_tva2)>0" @click="state.rDepense.lno_tx_tva2 = '10.00'" class="btn btn-sm btn-white text-white ms-2"><inline-svg class="fs-7" src="media/icons/duotune/arrows/arr087.svg" /></button>
                  </div>
              </div>

              <div v-if="state.rDepense.lno_categorie != 'trajet' && state.rDepense.lno_categorie != 'accommodation' && state.rDepense.lno_tx_tva2 > 0" class="row mb-5">
                  <label class="col-lg-4 col-form-label fw-semobold fs-6 required">Total TVA (2)</label>
                  <div class="col-lg-5 col-7 fv-row text-right mw-175px">
                    <div class="input-group">
                      <input :disabled="modeDisabled()" type="number" v-model="state.rDepense.lno_mnt_tva2" class="form-control ps-4" />
                      <span class="input-group-text">{{ currencSymbol(state.rDepense.lno_currency) }}</span>
                    </div>
                      
                  </div>
                  <div class="col-lg-3 col-3 fv-row d-flex w-100px">
                    <select :disabled="modeDisabled()" name="tva2" v-model="state.rDepense.lno_tx_tva2" data-control="select2" data-hide-search="true" class="form-select form-select fw-bolder  w-125px" >
                      <option value="5.50" >à 5.5 %</option>
                      <option value="10.00" >à 10 %</option>
                      <option value="20.00">à 20 %</option>
                    </select>
                    <button @click="state.rDepense.lno_tx_tva2 = ''; state.rDepense.lno_mnt_tva2 = 0" class="btn btn-sm btn-white text-white ms-2"><inline-svg class="fs-7" src="media/icons/duotune/arrows/arr089.svg" /></button>
                  </div>
              </div>

          </div>
        </div>

        <div v-if="state.rDepense.lno_categorie == 'accommodation'
                    || state.rDepense.lno_categorie == 'transport'
                    || state.rDepense.lno_categorie == 'trajet'" 
              class="card mb-5 mb-xxl-8 mx-4 mx-md-8" >
          <div class="card-body pt-2 pt-md-5 pb-0">
            <div class="row mb-5">
                <label class="col-lg-4 col-form-label fw-semobold fs-6 required">Motif du déplacement</label>
                <div class="col-lg-8 col-12 fv-row">
                  <textarea :disabled="modeDisabled()" v-model="state.rDepense.lno_motif" class="form-control form-control-solid rounded-3" rows="2"/>
                </div>
            </div>
          </div>
        </div>

        <div v-if="state.rDepense.lno_categorie == 'food'" class="card mb-5 mb-xxl-8 mx-4 mx-md-8" >
          <div class="card-body pt-2 pt-md-5 pb-0">
            <div class="row mb-5">
                <label class="col-lg-4 col-form-label fw-semobold fs-6 required">Nombre de repas</label>
                <div class="col-lg-8 col-12 fv-row w-250px">
                  <div class="input-group">
                    <input :disabled="modeDisabled()" v-model="state.rDepense.lno_nb_repas" @input="addRepas" type="number" class="form-control ps-4" />
                    <span class="input-group-text">Repas</span>
                  </div>
                </div>
            </div>
          </div>

          <div class="card-body pt-2 pt-md-5 pb-0" v-if="parseInt(state.nbRepasCopy) > 0">
            <div class="row mb-5" v-for="index in parseInt(state.nbRepasCopy)" :key="index">
                <label class="col-lg-4 col-form-label fw-semobold fs-6">Repas {{ index }} - Identité / Fonction</label>
                <div class="col-lg-4 col-6 fv-row">
                    <input :disabled="modeDisabled()" type="texte" v-model="state.rDepense.lno_infos_personnes_repas[index-1].nomprenom" placeholder="Prénom Nom" class="form-control ps-4" />
                </div>
                <div class="col-lg-4 col-6 fv-row">
                    <input :disabled="modeDisabled()" type="texte" v-model="state.rDepense.lno_infos_personnes_repas[index-1].fonction" placeholder="Fonction" class="form-control ps-4" />
                </div>
            </div>
          </div>
        </div>

        <div v-if="state.rDepense.lno_categorie == 'trajet'" class="card mb-5 mb-xxl-8 mx-4 mx-md-8" >
          <div class="card-body pt-2 pt-md-5 pb-0">
            <div class="row mb-5">
                <label class="col-lg-4 col-form-label fw-semobold fs-6">Kilométrage</label>
                <div class="col-lg-8 col-12 fv-row w-250px">
                  <div class="input-group">
                    <input :disabled="modeDisabled()" @input="calculFraisKM(state.rDepense.lno_categ_vehicule, state.rDepense.lno_kilometrage)" v-model="state.rDepense.lno_kilometrage" type="number" class="form-control ps-4" />
                    <span class="input-group-text">kms</span>
                  </div>
                </div>
            </div>
            <div class="row mb-5">
                <label class="col-lg-4 col-form-label fw-semobold fs-6">Catégorie véhicule</label>
                <div class="col-lg-8 col-12 fv-row w-250px">
                    <select :disabled="modeDisabled()" @change="calculFraisKM(state.rDepense.lno_categ_vehicule, state.rDepense.lno_kilometrage)" v-model="state.rDepense.lno_categ_vehicule" name="status" data-control="select2" data-hide-search="true" class="form-select form-select fw-bolder  w-250px" >
                      <option :value="3"> 3 CV et moins</option>
                      <option :value="4"> 4 CV</option>
                      <option :value="5"> 5 CV</option>
                      <option :value="6"> 6 CV</option>
                      <option :value="7"> 7 CV et plus</option>

                    </select>
                </div>
            </div>
            <div class="row mb-5">
                <label class="col-lg-4 col-form-label fw-semobold fs-6">Soit un montant de</label>
                <div class="col-lg-4 col-8 fv-row w-250px">
                  <div class="input-group">
                    <input  type="number" disabled class="form-control ps-4" v-model="state.rDepense.lno_montant"/>
                    <span class="input-group-text">{{ currencSymbol(state.rDepense.lno_currency) }}</span>
                  </div>
                </div>
                <div class="col-lg-4 col-10 col-sm-6 fv-row mt-2 mt-sm-0">
                  <input class="input-group input-group-text disabled fs-8 h-100" disabled v-model="state.calculFraisKMString">
                </div>
            </div>
          </div>
        </div>

        <div class="card mb-5 mb-xxl-8 mx-4 mx-md-8" >
          <div class="card-body pt-2 pt-md-5 pb-0">
            <div class="row mb-5">
                  <label class="col-lg-4 col-form-label fw-semobold fs-6">Client / Prospect</label>
                  <div class="col-lg-8 col-12 fv-row">
                      <input :disabled="modeDisabled()" v-model="state.rDepense.lno_client_libre" type="text" class="form-control ps-4" />
                  </div>
            </div>
            <div class="row mb-5">
                <label class="col-lg-4 col-form-label fw-semobold fs-6">Commentaires</label>
                <div class="col-lg-8 col-12 fv-row">
                    <textarea :disabled="modeDisabled()" v-model="state.rDepense.lno_remarques" class="form-control form-control-solid rounded-3" rows="4"/>
                </div>
            </div>
          </div>
        </div>


      </div>

    </div>

    <el-dialog v-model="dialogVisible" class="mt-0 w-100 ">
      <iframe w-full :src="dialogImageUrl" class="mt-0 w-100 h-100" alt="Preview Image" />
    </el-dialog>


    <br><br><br>
      
    </div>

    <div v-else v-loading="true" style="position: absolute; top: 50%; left: 50%"></div>
  </transition>

</template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted, reactive, defineExpose } from "vue";
  import axios from "axios";
  import mAxiosApi from "@/api";
  import { ZoomIn } from '@element-plus/icons';
  import { ElMessageBox, ElNotification } from 'element-plus'
  import * as moment from "moment";
import { FileService } from "@/core/services/FileService";
  
   
  export default defineComponent({
    name: "General",
    components : {
        ZoomIn
    },
    props : {
      idN2F : Number,
      idFolder: Number,
      idDepense : Number,
      isOpen : Number,
      perm: Boolean,
    },
    setup(props, { emit }) {

    const dialogImageUrl = ref('')
    const dialogVisible = ref(false)
    const disabled = ref(false)
    const N2FfileService = new FileService("n2f")

    const state = reactive({
      drawerAdd : false,
      modeAJt : 0,
      masqueSubStitle : 0,
      rDepense : {} as any,
      currency : {} as any,
      loading : 1,
      jsonStart : "",
      jsonEnd : "",
      title: "NOUVELLE DÉPENSE",
      uploadedFiles: [] as any,
      actualFiles: [] as any,
      newFiles: [] as any,
      loadingUpload: false,
      bareme: {} as any,
      calculFraisKMString: "",
      nbRepasCopy: '0'
    });
    
    let result = ref<Array<{ item: string; valeur: string }>>([]); // Initialisez result comme un tableau vide

    const close = () => {
      emit("closeDepense", 0);
    };

    function dataURLtoBlob(dataurl) {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {type:mime});
    }

    onMounted(async() => {
      if (!props.idDepense) {
        const response = await mAxiosApi.prototype.getAxios("/getCurrency");
        state.currency = response.results[0];
        addNewDepense();
        
      }else {
        state.modeAJt = 1;
        state.title = "Modifier une dépense";
        const l2f = await mAxiosApi.prototype.getAxios("/getLngN2F/" + props.idDepense);
        state.rDepense = l2f.results;
        state.rDepense.lno_infos_personnes_repas = JSON.parse(state.rDepense.lno_infos_personnes_repas);
        state.nbRepasCopy = state.rDepense.lno_nb_repas;
        console.log(state.nbRepasCopy)
        if (!state.rDepense.lno_infos_personnes_repas) state.rDepense.lno_infos_personnes_repas = [];
        
        const response = await mAxiosApi.prototype.getAxios("/getCurrency");
        state.currency = response.results[0];
        state.currency[state.rDepense.lno_currency] = state.rDepense.lno_tx_devise
        
        state.rDepense.lno_montant = state.rDepense.lno_montant_devise;
        state.rDepense.lno_categ_vehicule = state.rDepense.lno_categ_vehicule ? state.rDepense.lno_categ_vehicule : 3;

        const fileData = await N2FfileService.getGedFile(null, props.idDepense) as any;
        fileData.forEach(async doc => {
          let url = "data:" + (doc.filetype == "pdf" ? "application" : "image") + "/" + doc.filetype + ";base64," + doc.ged_document as any;
          if (doc.filetype == "pdf") {
            url = dataURLtoBlob(url);
            url = window.URL.createObjectURL(url);
          }

          state.uploadedFiles.push({name: doc.ged_title, url: url})
          state.actualFiles.push({name: doc.ged_title, url: url})
        })

        if (modeDisabled()) state.title = "Afficher une dépense";

      }
      
      state.jsonStart = JSON.stringify(state.rDepense);
      state.loading = 0;
      if (state.rDepense.lno_date && state.rDepense.lno_categorie == 'trajet') {
        await changeBareme();
      }
      console.log(state.rDepense)
      if (state.rDepense.lno_kilometrage > 0 && state.rDepense.lno_categorie == 'trajet') {
        calculFraisKM(state.rDepense.lno_categ_vehicule, state.rDepense.lno_kilometrage, true);
      }
    });

    const modeDisabled = () => {
      if (state.rDepense.not_status=='Brouillon' || state.rDepense.not_status=='Refusé') return false;
      return true;
    };
     
    const currencSymbol = (currency) =>{
      if (currency == 'EUR') return '€';
      if (currency == 'USD') return '$';
      if (currency == 'CHF') return 'CHF';
      if (currency == 'CAD') return '$ CAD';
    };

    const totalEuro = (total) =>{
      const currency = state.rDepense.lno_currency;
      if (currency == 'EUR') return total;
      const mTotal = state.currency[currency]*total;

      return parseFloat(mTotal.toFixed(2));
    };

    const changeCateg = (e) => {
      state.rDepense.lno_categorie = e.target.value;
      state.rDepense.lno_tx_tva1 = '20';
      if (state.rDepense.lno_categorie == 'food') state.rDepense.lno_tx_tva1 = '10';
      if (state.rDepense.lno_categorie == 'trajet') changeBareme();
    }

    const addN2F = async () => {
      state.rDepense.lno_tx_devise = state.currency[state.rDepense.lno_currency];
      state.rDepense.lno_montant_devise = state.rDepense.lno_montant;
      state.rDepense.lno_montant = totalEuro(state.rDepense.lno_montant);
      state.rDepense.lno_infos_personnes_repas = JSON.stringify(state.rDepense.lno_infos_personnes_repas);
      mAxiosApi.post("/addLngN2F", state.rDepense)
      .then((res) => {
        emit("refresh", 1);
        state.newFiles.forEach(file => {
          const idDepense = props.idDepense as any > 0 ? props.idDepense : res.data.results
          N2FfileService.uploadFile(props.idFolder as any, file.raw, idDepense)
        });
        close();
      });
    };
    
    const addNewDepense = async () => {
      
      state.rDepense.lno_seq = null;
      state.rDepense.lno_knum_note_de_frais = props.idN2F;
      state.rDepense.lno_categorie = 'miscellaneous';
      state.rDepense.lno_description = null;
      state.rDepense.lno_remarques = null;
      state.rDepense.lno_montant = 0;
      state.rDepense.lno_fichier = null;
      state.rDepense.lno_date = null;
      state.rDepense.lno_montant_tva = 0;
      state.rDepense.lno_currency = 'EUR';
      state.rDepense.lno_nb_repas = 0;
      state.rDepense.lno_motif = null;
      state.rDepense.lno_mnt_tva1 = 0;
      state.rDepense.lno_mnt_tva2 = 0;
      state.rDepense.lno_tx_tva1 = '20.00';
      state.rDepense.lno_tx_tva2 = 0;
      state.rDepense.lno_kilometrage = 0;
      state.rDepense.lno_categ_vehicule = 3;
      state.rDepense.lno_client_libre = '';
      state.rDepense.lno_tx_devise = 0;
      state.rDepense.lno_montant_devise = null;
      state.rDepense.not_status = 'Brouillon';
      state.rDepense.lno_infos_personnes_repas = [];
    };

    const handlePictureCardPreview = (file) => {
      const acceptedDrawer = ["jpg", "jpeg", "bmp", "png", "gif", "svg", "tiff", "webp", "pdf"];
      if (acceptedDrawer.indexOf(file.name.split(".")[1]) > -1) {
        dialogImageUrl.value = file.url!
        dialogVisible.value = true
      }else{
        const linkSource = file.url!;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = file.name;
        downloadLink.click();

      }
    }

    const handleChange = async (uploadFile) => {
        state.loadingUpload = true;
        state.masqueSubStitle = 1;
        const formData = new FormData();
        formData.append("document", uploadFile.raw);
  
        try {
          const response = await axios.post(
            "https://api.mindee.net/v1/products/mindee/financial_document/v1/predict",
            formData,
            {
              headers: {
                Authorization: "30216adb19b2d1721c1d3e98e5b9901f", // Remplacez par votre clé d'API  3f074e3923335fc490cfc7cbbd6f2abd
                "Content-Type": "multipart/form-data",
              },
              withCredentials: false,
            }
          );
 
          result.value = [];
  
          const arrayProd = response.data.document.inference.product.features;
  
          arrayProd.forEach((val) => {
            const theProd = response.data.document.inference.pages[0].prediction[val];
            if (theProd.length > 1) {
              theProd.forEach((val2) => {
                result.value.push({ item: val, valeur: val2 });
              });
            } else {
              if (theProd.value) result.value.push({ item: val, valeur: theProd.value });
              if (theProd.currency) result.value.push({ item: val, valeur: theProd.currency });
            }
          });

          state.rDepense.lno_description = result.value.find((el) => { return el.item == "supplier_name"; })?.valeur;

          state.rDepense.lno_date = result.value.find((el) => { return el.item == "date"; })?.valeur;

          state.rDepense.lno_categorie = result.value.find((el) => { return el.item == "category"; })?.valeur;
          state.rDepense.lno_tx_tva1 = '20.00';
          state.rDepense.lno_tx_tva2 = '';
          if (state.rDepense.lno_categorie == 'food') state.rDepense.lno_tx_tva1 = '10.00';

          state.rDepense.lno_montant = result.value.find((el) => { return el.item == "total_amount"; })?.valeur;

          const curr = result.value.find((el) => { return el.item == "locale"; })?.valeur;
          if (curr == 'EUR' || curr == 'CHF' || curr == 'USD' || curr == 'CAD' ) {
            state.rDepense.lno_currency = result.value.find((el) => { return el.item == "locale"; })?.valeur;
          }else {
            state.rDepense.lno_currency = 'EUR';
          }
          
          state.rDepense.lno_mnt_tva1 = 0;
          state.rDepense.lno_mnt_tva2 = 0;

          const taxes = response.data.document.inference.prediction.taxes;
          if (taxes.length == 1) {
            state.rDepense.lno_mnt_tva1 = taxes[0].value;
            state.rDepense.lno_tx_tva1 = taxes[0].rate.toFixed(2);
          }
          if (taxes.length == 2) {
            state.rDepense.lno_mnt_tva1 = taxes[0].value
            state.rDepense.lno_tx_tva1 = taxes[0].rate.toFixed(2);

            state.rDepense.lno_mnt_tva2 = taxes[1].value;
            state.rDepense.lno_tx_tva2 = taxes[1].rate.toFixed(2);

          }

          state.newFiles.push(uploadFile)
          state.actualFiles.push({name: uploadFile.name, url: uploadFile.url})
          state.modeAJt = 1;

        } catch (error) {
          console.error(error);
          state.uploadedFiles = [...state.actualFiles]
          ElNotification({
            type: "error",
            title: 'Erreur',
            message: 'Veuillez vérifier que votre fichier n\'est pas trop lourd ou corrompu.'
          })
          state.modeAJt = 1;
        }
        console.log(state.actualFiles)
        state.loadingUpload = false;
    }

   
      

      const toDate = (d) => {
        return moment.default(d).format("DD.MM.YYYY");
      };

      const handleCloseL2f = (done: () => void) => {
        
        if (state.jsonStart == JSON.stringify( state.rDepense )) {
          done();
          return false;
        }

        if (state.modeAJt == 0){
          done();
          return false;
        } 
        ElMessageBox({
            title: 'Confirmation', 
            message: 'Vos données non sauvegardées seront perdues, continuer ?', 
            customClass: "responsiveMessageBox",
            showCancelButton: true,
            confirmButtonText: 'OK',  
            cancelButtonText: 'Annuler'})
          .then(() => { done(); })
      }


      const removeL2F = () => {
        const rL2F = {
          lno_seq: state.rDepense.lno_seq,
        };

        mAxiosApi.post("/deleteL2F", rL2F)
          .then(() => {
            emit("refresh", 1);
            close();
          })

      };

      const calcTVA = () => {
        state.rDepense.lno_mnt_tva1 = (state.rDepense.lno_montant - (state.rDepense.lno_montant / ((100 + parseFloat(state.rDepense.lno_tx_tva1)) / 100))).toFixed(2);
      };

      const ValidConfirm = () => {
        close();
      };


      const calculFraisKM = (chevaux, distance, initialize=false) => {
        if (!state.rDepense.lno_date) {
          state.rDepense.lno_kilometrage = 0;
          state.rDepense.lno_categ_vehicule = 3;
          state.rDepense.lno_montant = 0;
          state.calculFraisKMString = "";
          !initialize && ElNotification({
            title: 'Attention',
            message: 'Vous devez d\'abord entrer la date',
            type: 'error',
            duration: 3000
          })
          return;
        }
        distance = distance ? distance : 0
        if (chevaux <= 3) {
          chevaux = 3
        }
        if (chevaux >= 7) {
          chevaux = 7
        }
        const lineBareme = state.bareme.find((element) => element.bar_cv == chevaux)

        if (distance<=5000) {
          state.rDepense.lno_montant = Math.round((lineBareme.bar_cinqmille*distance)* 100) / 100
          state.calculFraisKMString = chevaux + "CV : " + distance + " * " + lineBareme.bar_cinqmille
        }
        if (distance>5000 && distance<=20000) {
          state.rDepense.lno_montant = Math.round(((lineBareme.bar_vingtmille*distance) + parseFloat(lineBareme.bar_add) )* 100) / 100
          state.calculFraisKMString = chevaux + "CV : (" + distance + " * " + lineBareme.bar_vingtmille + ") + " + lineBareme.bar_add
        }
        if (distance>20000) {
          state.rDepense.lno_montant = Math.round((lineBareme.bar_vingmilleplus*distance)* 100) / 100
          state.calculFraisKMString = chevaux + "CV : " + distance + " * " + lineBareme.bar_vingmilleplus
        }
      }

      const changeBareme = async()=> {
        if (state.rDepense.lno_categorie == 'trajet') {
          const response = await mAxiosApi.prototype.getAxios("/getFraisKM/" + new Date(state.rDepense.lno_date).getFullYear())
          state.bareme = response.results
          calculFraisKM(state.rDepense.lno_categ_vehicule, state.rDepense.lno_kilometrage, true)
        }
      }

      const addRepas = () => {
        if (state.rDepense.lno_nb_repas < state.rDepense.lno_infos_personnes_repas.length) {
          const numberToPop = state.rDepense.lno_infos_personnes_repas.length - state.rDepense.lno_nb_repas
          for (let index = 0; index < numberToPop; index++) {
            state.rDepense.lno_infos_personnes_repas.pop()
          }
          state.nbRepasCopy = state.rDepense.lno_nb_repas;
          return;
        }
        const numberToIncrement = state.rDepense.lno_nb_repas - state.rDepense.lno_infos_personnes_repas.length;
        for (let index = 0; index < numberToIncrement; index++) {
          state.rDepense.lno_infos_personnes_repas.push({'nomprenom': '', 'fonction': ''})
          state.nbRepasCopy = state.rDepense.lno_nb_repas;
        }
      }
 
      defineExpose({
        handleCloseL2f,
        ValidConfirm
      });

      return {
          result,
          disabled,
          handlePictureCardPreview,
          dialogImageUrl,
          dialogVisible,
          handleChange,
          state,
          addNewDepense,
          currencSymbol,
          totalEuro,
          changeCateg,
          toDate,
          handleCloseL2f,
          ValidConfirm,
          close,
          addN2F,
          modeDisabled,
          removeL2F,
          calcTVA,
          calculFraisKM,
          changeBareme,
          addRepas
      };
    },
  });
  </script>
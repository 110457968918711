import mAxiosApi from "@/api";
import { App } from "vue";

/**
 * @description service to call file compresser and uploading
 */
export class FileService  {

  private serviceType: string;
  constructor(serviceType){
    this.serviceType = serviceType
  }

  /**
   * @description send the files in the database
   * @param file: file to send
   * @param {}: optionnal, define the quality and the file type
   */
    public static async compressImage(
      file,
      {quality = .7, type = file.type}
    ) {
      const imageBitmap = await createImageBitmap(file);
      const canvas = document.createElement('canvas');
      const typeImg = imageBitmap.width > imageBitmap.height ? "W" : "H";
      const typeSize = typeImg == "W" ? imageBitmap.width : imageBitmap.height;
      let ratio = 1;
      if (typeSize > 1800) {
      if (typeImg == "W" ) {
          ratio = imageBitmap.width / imageBitmap.height;
          canvas.width = 1800;
          canvas.height = 1800 / ratio;
      } else {
          ratio = imageBitmap.height / imageBitmap.width;
          canvas.height = 1800;
          canvas.width = 1800 / ratio;
      }
      }else {
        canvas.width = imageBitmap.width;
        canvas.height = imageBitmap.height;
      }
      
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(imageBitmap, 0,0, imageBitmap.width, imageBitmap.height,0 ,0 , canvas.width, canvas.height); 
      } 
  
      return await new Promise((resolve) =>
        canvas.toBlob(resolve, type, quality)
      );
    }


  /**
   * @description send the files in the database
   * @param parent_seq: parent's folder seq
   * @param file: uploaded file
   * @param knum_n2f: n2f's seq
   */
    public async uploadFile(
      parent_seq: number | string | null,
      file,
      knum_n2f = null,
      knum_employe = 0,
      sendMail = false,
    ) {
      let change_factor;
      if (this.serviceType == "ged") {
        change_factor = {
          name: "ged_knum_employe",
          value: knum_employe
        }
      }
      if (this.serviceType == "n2f") {
        change_factor = {
          name: "ged_knum_n2f",
          value: knum_n2f
        }
      }
      const wait = await new Promise((resolve)=>{
        if (file) {
          const reader = new FileReader();
          
          if (file.type.startsWith('image')) {
            let compressedFile;
            (async () => {
              compressedFile = await FileService.compressImage(file, {
                  quality: 0.7,
                  type: 'image/jpeg',
              });
              reader.readAsDataURL(compressedFile);
            })();
          }else {
            reader.readAsDataURL(file);
          }
          reader.onload = async function(e) {
            if(e.target){
              const form = {
                ged_title: file.name,
                ged_c_type: file.type,
                ged_document: e.target?.result,
                ged_size: file.size,
                ged_knum_folder: parent_seq,
                ged_knum_employe: knum_employe,
                ged_knum_n2f: knum_n2f,
                sendMail: sendMail,
              };
              await mAxiosApi.post("/uploadFileGed", form);
              resolve(e.target.result);
            } 
          };
        }
      })
    }

  /**
   * @description create a folder
   * @param label: label
   * @param parent_seq: parent's folder seq
   * @param isPrivate: private or not
   */
    public async createFolder(
      label: string | number,
      parent_seq = null,
      isPrivate = true,
      emp_seq = 0,
    ) {
    if (this.serviceType == "n2f") {
      const folderData = {
        gfo_label: "Notes de frais",
        gfo_knum_parent: null,
        isPrivate: false
      };
      const allN2f_seq = await mAxiosApi.post("/createFolder", folderData);
      parent_seq = allN2f_seq.data.results
    }
    const folderData = {
      gfo_label: label,
      gfo_knum_parent: parent_seq,
      isPrivate: isPrivate,
      emp_seq: emp_seq,
    };
    const response = await mAxiosApi.post("/createFolder", folderData);
    if (this.serviceType == "n2f") {
      return response.data.results
    }else{
      return response.data.exists == 1 ? true : false
    }
  }


  /**
   * @description create a folder
   * @param ged_seq: file's seq
   * @param knum_n2f: n2f's seq
   */
  public async getGedFile(
    ged_seq: string | number | null,
    knum_n2f: string | number | null = 0,
  ) {
    const response = (await mAxiosApi.prototype.getAxios("/getGedFile/" + ged_seq + "/" + knum_n2f))
    if (knum_n2f) {
      return response.records
    }else{
      return response.records[0]
    }
  }
}


  import { defineComponent, ref, onMounted, reactive, defineExpose } from "vue";
  import axios from "axios";
  import mAxiosApi from "@/api";
  import { ZoomIn } from '@element-plus/icons';
  import { ElMessageBox, ElNotification } from 'element-plus'
  import * as moment from "moment";
import { FileService } from "@/core/services/FileService";
  
   
  export default defineComponent({
    name: "General",
    components : {
        ZoomIn
    },
    props : {
      idN2F : Number,
      idFolder: Number,
      idDepense : Number,
      isOpen : Number,
      perm: Boolean,
    },
    setup(props, { emit }) {

    const dialogImageUrl = ref('')
    const dialogVisible = ref(false)
    const disabled = ref(false)
    const N2FfileService = new FileService("n2f")

    const state = reactive({
      drawerAdd : false,
      modeAJt : 0,
      masqueSubStitle : 0,
      rDepense : {} as any,
      currency : {} as any,
      loading : 1,
      jsonStart : "",
      jsonEnd : "",
      title: "NOUVELLE DÉPENSE",
      uploadedFiles: [] as any,
      actualFiles: [] as any,
      newFiles: [] as any,
      loadingUpload: false,
      bareme: {} as any,
      calculFraisKMString: "",
      nbRepasCopy: '0'
    });
    
    let result = ref<Array<{ item: string; valeur: string }>>([]); // Initialisez result comme un tableau vide

    const close = () => {
      emit("closeDepense", 0);
    };

    function dataURLtoBlob(dataurl) {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {type:mime});
    }

    onMounted(async() => {
      if (!props.idDepense) {
        const response = await mAxiosApi.prototype.getAxios("/getCurrency");
        state.currency = response.results[0];
        addNewDepense();
        
      }else {
        state.modeAJt = 1;
        state.title = "Modifier une dépense";
        const l2f = await mAxiosApi.prototype.getAxios("/getLngN2F/" + props.idDepense);
        state.rDepense = l2f.results;
        state.rDepense.lno_infos_personnes_repas = JSON.parse(state.rDepense.lno_infos_personnes_repas);
        state.nbRepasCopy = state.rDepense.lno_nb_repas;
        console.log(state.nbRepasCopy)
        if (!state.rDepense.lno_infos_personnes_repas) state.rDepense.lno_infos_personnes_repas = [];
        
        const response = await mAxiosApi.prototype.getAxios("/getCurrency");
        state.currency = response.results[0];
        state.currency[state.rDepense.lno_currency] = state.rDepense.lno_tx_devise
        
        state.rDepense.lno_montant = state.rDepense.lno_montant_devise;
        state.rDepense.lno_categ_vehicule = state.rDepense.lno_categ_vehicule ? state.rDepense.lno_categ_vehicule : 3;

        const fileData = await N2FfileService.getGedFile(null, props.idDepense) as any;
        fileData.forEach(async doc => {
          let url = "data:" + (doc.filetype == "pdf" ? "application" : "image") + "/" + doc.filetype + ";base64," + doc.ged_document as any;
          if (doc.filetype == "pdf") {
            url = dataURLtoBlob(url);
            url = window.URL.createObjectURL(url);
          }

          state.uploadedFiles.push({name: doc.ged_title, url: url})
          state.actualFiles.push({name: doc.ged_title, url: url})
        })

        if (modeDisabled()) state.title = "Afficher une dépense";

      }
      
      state.jsonStart = JSON.stringify(state.rDepense);
      state.loading = 0;
      if (state.rDepense.lno_date && state.rDepense.lno_categorie == 'trajet') {
        await changeBareme();
      }
      console.log(state.rDepense)
      if (state.rDepense.lno_kilometrage > 0 && state.rDepense.lno_categorie == 'trajet') {
        calculFraisKM(state.rDepense.lno_categ_vehicule, state.rDepense.lno_kilometrage, true);
      }
    });

    const modeDisabled = () => {
      if (state.rDepense.not_status=='Brouillon' || state.rDepense.not_status=='Refusé') return false;
      return true;
    };
     
    const currencSymbol = (currency) =>{
      if (currency == 'EUR') return '€';
      if (currency == 'USD') return '$';
      if (currency == 'CHF') return 'CHF';
      if (currency == 'CAD') return '$ CAD';
    };

    const totalEuro = (total) =>{
      const currency = state.rDepense.lno_currency;
      if (currency == 'EUR') return total;
      const mTotal = state.currency[currency]*total;

      return parseFloat(mTotal.toFixed(2));
    };

    const changeCateg = (e) => {
      state.rDepense.lno_categorie = e.target.value;
      state.rDepense.lno_tx_tva1 = '20';
      if (state.rDepense.lno_categorie == 'food') state.rDepense.lno_tx_tva1 = '10';
      if (state.rDepense.lno_categorie == 'trajet') changeBareme();
    }

    const addN2F = async () => {
      state.rDepense.lno_tx_devise = state.currency[state.rDepense.lno_currency];
      state.rDepense.lno_montant_devise = state.rDepense.lno_montant;
      state.rDepense.lno_montant = totalEuro(state.rDepense.lno_montant);
      state.rDepense.lno_infos_personnes_repas = JSON.stringify(state.rDepense.lno_infos_personnes_repas);
      mAxiosApi.post("/addLngN2F", state.rDepense)
      .then((res) => {
        emit("refresh", 1);
        state.newFiles.forEach(file => {
          const idDepense = props.idDepense as any > 0 ? props.idDepense : res.data.results
          N2FfileService.uploadFile(props.idFolder as any, file.raw, idDepense)
        });
        close();
      });
    };
    
    const addNewDepense = async () => {
      
      state.rDepense.lno_seq = null;
      state.rDepense.lno_knum_note_de_frais = props.idN2F;
      state.rDepense.lno_categorie = 'miscellaneous';
      state.rDepense.lno_description = null;
      state.rDepense.lno_remarques = null;
      state.rDepense.lno_montant = 0;
      state.rDepense.lno_fichier = null;
      state.rDepense.lno_date = null;
      state.rDepense.lno_montant_tva = 0;
      state.rDepense.lno_currency = 'EUR';
      state.rDepense.lno_nb_repas = 0;
      state.rDepense.lno_motif = null;
      state.rDepense.lno_mnt_tva1 = 0;
      state.rDepense.lno_mnt_tva2 = 0;
      state.rDepense.lno_tx_tva1 = '20.00';
      state.rDepense.lno_tx_tva2 = 0;
      state.rDepense.lno_kilometrage = 0;
      state.rDepense.lno_categ_vehicule = 3;
      state.rDepense.lno_client_libre = '';
      state.rDepense.lno_tx_devise = 0;
      state.rDepense.lno_montant_devise = null;
      state.rDepense.not_status = 'Brouillon';
      state.rDepense.lno_infos_personnes_repas = [];
    };

    const handlePictureCardPreview = (file) => {
      const acceptedDrawer = ["jpg", "jpeg", "bmp", "png", "gif", "svg", "tiff", "webp", "pdf"];
      if (acceptedDrawer.indexOf(file.name.split(".")[1]) > -1) {
        dialogImageUrl.value = file.url!
        dialogVisible.value = true
      }else{
        const linkSource = file.url!;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = file.name;
        downloadLink.click();

      }
    }

    const handleChange = async (uploadFile) => {
        state.loadingUpload = true;
        state.masqueSubStitle = 1;
        const formData = new FormData();
        formData.append("document", uploadFile.raw);
  
        try {
          const response = await axios.post(
            "https://api.mindee.net/v1/products/mindee/financial_document/v1/predict",
            formData,
            {
              headers: {
                Authorization: "30216adb19b2d1721c1d3e98e5b9901f", // Remplacez par votre clé d'API  3f074e3923335fc490cfc7cbbd6f2abd
                "Content-Type": "multipart/form-data",
              },
              withCredentials: false,
            }
          );
 
          result.value = [];
  
          const arrayProd = response.data.document.inference.product.features;
  
          arrayProd.forEach((val) => {
            const theProd = response.data.document.inference.pages[0].prediction[val];
            if (theProd.length > 1) {
              theProd.forEach((val2) => {
                result.value.push({ item: val, valeur: val2 });
              });
            } else {
              if (theProd.value) result.value.push({ item: val, valeur: theProd.value });
              if (theProd.currency) result.value.push({ item: val, valeur: theProd.currency });
            }
          });

          state.rDepense.lno_description = result.value.find((el) => { return el.item == "supplier_name"; })?.valeur;

          state.rDepense.lno_date = result.value.find((el) => { return el.item == "date"; })?.valeur;

          state.rDepense.lno_categorie = result.value.find((el) => { return el.item == "category"; })?.valeur;
          state.rDepense.lno_tx_tva1 = '20.00';
          state.rDepense.lno_tx_tva2 = '';
          if (state.rDepense.lno_categorie == 'food') state.rDepense.lno_tx_tva1 = '10.00';

          state.rDepense.lno_montant = result.value.find((el) => { return el.item == "total_amount"; })?.valeur;

          const curr = result.value.find((el) => { return el.item == "locale"; })?.valeur;
          if (curr == 'EUR' || curr == 'CHF' || curr == 'USD' || curr == 'CAD' ) {
            state.rDepense.lno_currency = result.value.find((el) => { return el.item == "locale"; })?.valeur;
          }else {
            state.rDepense.lno_currency = 'EUR';
          }
          
          state.rDepense.lno_mnt_tva1 = 0;
          state.rDepense.lno_mnt_tva2 = 0;

          const taxes = response.data.document.inference.prediction.taxes;
          if (taxes.length == 1) {
            state.rDepense.lno_mnt_tva1 = taxes[0].value;
            state.rDepense.lno_tx_tva1 = taxes[0].rate.toFixed(2);
          }
          if (taxes.length == 2) {
            state.rDepense.lno_mnt_tva1 = taxes[0].value
            state.rDepense.lno_tx_tva1 = taxes[0].rate.toFixed(2);

            state.rDepense.lno_mnt_tva2 = taxes[1].value;
            state.rDepense.lno_tx_tva2 = taxes[1].rate.toFixed(2);

          }

          state.newFiles.push(uploadFile)
          state.actualFiles.push({name: uploadFile.name, url: uploadFile.url})
          state.modeAJt = 1;

        } catch (error) {
          console.error(error);
          state.uploadedFiles = [...state.actualFiles]
          ElNotification({
            type: "error",
            title: 'Erreur',
            message: 'Veuillez vérifier que votre fichier n\'est pas trop lourd ou corrompu.'
          })
          state.modeAJt = 1;
        }
        console.log(state.actualFiles)
        state.loadingUpload = false;
    }

   
      

      const toDate = (d) => {
        return moment.default(d).format("DD.MM.YYYY");
      };

      const handleCloseL2f = (done: () => void) => {
        
        if (state.jsonStart == JSON.stringify( state.rDepense )) {
          done();
          return false;
        }

        if (state.modeAJt == 0){
          done();
          return false;
        } 
        ElMessageBox({
            title: 'Confirmation', 
            message: 'Vos données non sauvegardées seront perdues, continuer ?', 
            customClass: "responsiveMessageBox",
            showCancelButton: true,
            confirmButtonText: 'OK',  
            cancelButtonText: 'Annuler'})
          .then(() => { done(); })
      }


      const removeL2F = () => {
        const rL2F = {
          lno_seq: state.rDepense.lno_seq,
        };

        mAxiosApi.post("/deleteL2F", rL2F)
          .then(() => {
            emit("refresh", 1);
            close();
          })

      };

      const calcTVA = () => {
        state.rDepense.lno_mnt_tva1 = (state.rDepense.lno_montant - (state.rDepense.lno_montant / ((100 + parseFloat(state.rDepense.lno_tx_tva1)) / 100))).toFixed(2);
      };

      const ValidConfirm = () => {
        close();
      };


      const calculFraisKM = (chevaux, distance, initialize=false) => {
        if (!state.rDepense.lno_date) {
          state.rDepense.lno_kilometrage = 0;
          state.rDepense.lno_categ_vehicule = 3;
          state.rDepense.lno_montant = 0;
          state.calculFraisKMString = "";
          !initialize && ElNotification({
            title: 'Attention',
            message: 'Vous devez d\'abord entrer la date',
            type: 'error',
            duration: 3000
          })
          return;
        }
        distance = distance ? distance : 0
        if (chevaux <= 3) {
          chevaux = 3
        }
        if (chevaux >= 7) {
          chevaux = 7
        }
        const lineBareme = state.bareme.find((element) => element.bar_cv == chevaux)

        if (distance<=5000) {
          state.rDepense.lno_montant = Math.round((lineBareme.bar_cinqmille*distance)* 100) / 100
          state.calculFraisKMString = chevaux + "CV : " + distance + " * " + lineBareme.bar_cinqmille
        }
        if (distance>5000 && distance<=20000) {
          state.rDepense.lno_montant = Math.round(((lineBareme.bar_vingtmille*distance) + parseFloat(lineBareme.bar_add) )* 100) / 100
          state.calculFraisKMString = chevaux + "CV : (" + distance + " * " + lineBareme.bar_vingtmille + ") + " + lineBareme.bar_add
        }
        if (distance>20000) {
          state.rDepense.lno_montant = Math.round((lineBareme.bar_vingmilleplus*distance)* 100) / 100
          state.calculFraisKMString = chevaux + "CV : " + distance + " * " + lineBareme.bar_vingmilleplus
        }
      }

      const changeBareme = async()=> {
        if (state.rDepense.lno_categorie == 'trajet') {
          const response = await mAxiosApi.prototype.getAxios("/getFraisKM/" + new Date(state.rDepense.lno_date).getFullYear())
          state.bareme = response.results
          calculFraisKM(state.rDepense.lno_categ_vehicule, state.rDepense.lno_kilometrage, true)
        }
      }

      const addRepas = () => {
        if (state.rDepense.lno_nb_repas < state.rDepense.lno_infos_personnes_repas.length) {
          const numberToPop = state.rDepense.lno_infos_personnes_repas.length - state.rDepense.lno_nb_repas
          for (let index = 0; index < numberToPop; index++) {
            state.rDepense.lno_infos_personnes_repas.pop()
          }
          state.nbRepasCopy = state.rDepense.lno_nb_repas;
          return;
        }
        const numberToIncrement = state.rDepense.lno_nb_repas - state.rDepense.lno_infos_personnes_repas.length;
        for (let index = 0; index < numberToIncrement; index++) {
          state.rDepense.lno_infos_personnes_repas.push({'nomprenom': '', 'fonction': ''})
          state.nbRepasCopy = state.rDepense.lno_nb_repas;
        }
      }
 
      defineExpose({
        handleCloseL2f,
        ValidConfirm
      });

      return {
          result,
          disabled,
          handlePictureCardPreview,
          dialogImageUrl,
          dialogVisible,
          handleChange,
          state,
          addNewDepense,
          currencSymbol,
          totalEuro,
          changeCateg,
          toDate,
          handleCloseL2f,
          ValidConfirm,
          close,
          addN2F,
          modeDisabled,
          removeL2F,
          calcTVA,
          calculFraisKM,
          changeBareme,
          addRepas
      };
    },
  });
  

  import { defineComponent, ref, onMounted, reactive } from "vue";
  import mAxiosApi from "@/api";
  import lstItems from "./lstItems.vue";
  import DepenseAddEdit from "./DepenseAddEdit.vue";
  import { FileService } from "@/core/services/FileService";
  import * as moment from "moment"
   
  export default defineComponent({
    name: "General",
    components : {
        lstItems, DepenseAddEdit
    },
    props : {
      idN2F : Number,
      isOpen : Number,
      apiName : String,
    },
    setup(props, { emit }) {

    const dialogImageUrl = ref('')
    const dialogVisible = ref(false)
    const disabled = ref(false)

    const state = reactive({
        drawerAddItem : 0,
        masqueSubStitle : 0,
        rN2f : {} as any,
        currency : {} as any,
        title : 'Nouvelle note de frais',
        idDepense : 0,
        idFolder: 0,
        refresh : 0,
        idN2F : 0,
        allRest: {} as any,
        loading: true,
        comsHisto: false,
        newComment: '',
    });
    const N2FfileService = new FileService("n2f")
    
    onMounted(async() => {
      
      const allRest = await mAxiosApi.prototype.getAxios("/getPermissions");
      state.allRest =  allRest.results.filter(
          (item: any) =>item.per_code_permission == 'ERP_TRAITE_N2F'
      );

      if (!props.isOpen) return false;
      if (props.idN2F as any > 0) {
          const getN2F = await mAxiosApi.prototype.getAxios("/"+ props.apiName +"/" + props.idN2F);
          const getFolder = await N2FfileService.createFolder(("Note de frais " + getN2F.results[0].not_seq));
          state.idFolder = getFolder
          state.rN2f = getN2F.results[0];
          if (((state.rN2f.not_status == 'Brouillon' || state.rN2f.not_status == 'Refusé') && state.allRest && state.allRest.length != 0) ||
          ((state.rN2f.not_status != 'Payé') && state.allRest && state.allRest.length == 0)) {
            state.title = "Modifier une note de frais";
          }else{
            state.title = "Afficher une note de frais";
          }
      } else{
        const newN2F = await mAxiosApi.prototype.getAxios("/NewN2F");
        const newFolder = await N2FfileService.createFolder(("Note de frais " + newN2F.results.not_seq));
        state.idFolder = newFolder;
        const getN2F = await mAxiosApi.prototype.getAxios("/"+ props.apiName +"/" + newN2F.results.not_seq);
        state.rN2f = getN2F.results[0];
        refreshParent();
      }

      state.rN2f.not_json_historique = JSON.parse(state.rN2f.not_json_historique)
      if(!state.rN2f.not_json_historique) state.rN2f.not_json_historique = [];
      state.rN2f.not_json_historique = state.rN2f.not_json_historique.reverse();
      
      state.idN2F = state.rN2f.not_seq;

      const response = await mAxiosApi.prototype.getAxios("/getCurrency");
      state.currency = response.results[0];

      state.loading = false;

    });

    const addEditDepense = (id = 0) => {
      state.idDepense = id;
      state.drawerAddItem = 1;
    };

    const close = () => {
      emit("closeN2FAddEdit", 0);
    };

    const returnLineCateg = (categ) => {
      const arrayFilter = state.rN2f.lines.filter(
          (item: any) =>item.lno_categorie == categ
        );
        return arrayFilter;

    };

    

    const removeN2F = () => {
      const rN2F = {
        not_seq: state.rN2f.not_seq,
      };

      mAxiosApi.post("/deleteN2F", rN2F)
      .then(() => {
        refreshParent();
        close();
      })

    };

    const sendN2F = (status) => {
      const rN2F = {
        not_seq: state.rN2f.not_seq,
        not_status: status,
        newComment: state.newComment 
      };

      mAxiosApi.post("/sendN2F", rN2F)
      .then(() => {
        refreshParent();
        close();
      })

    };

    

    const modLabel = () => {

      const rLabel = {
        not_seq: state.rN2f.not_seq,
        not_libelle: state.rN2f.not_libelle,
      };

      mAxiosApi.post("/editLabelN2F", rLabel)
      .then(() => {
         refreshParent();
      })

    };

    const refreshParent = () => {
      emit("refresh", 1);
    };

    const mDepenseAddEdit = ref(null);
    const confirmDrawer = () => {
      (mDepenseAddEdit.value as any).handleCloseL2f((mDepenseAddEdit.value as any).ValidConfirm);
    };

    const refresh = async () => {
      const getN2F = await mAxiosApi.prototype.getAxios("/"+ props.apiName +"/" + state.idN2F);
      state.rN2f = getN2F.results[0];
      state.refresh = state.refresh +1;
      refreshParent();
    };

    const modeDisabled = () => {
      if (state.rN2f.not_status=='Brouillon' || state.rN2f.not_status=='Refusé') return false;
      return true;
    };

    const formatDate = (date) => {
      return moment.default(date).format("DD/MM/YYYY");
    }

      return {
          disabled,
          dialogImageUrl,
          dialogVisible,
          state,
          addEditDepense,
          close,
          returnLineCateg,
          refreshParent,
          refresh,
          modLabel,
          removeN2F,
          mDepenseAddEdit,
          confirmDrawer,
          sendN2F,
          modeDisabled,
          formatDate,
      };
    },
  });
  